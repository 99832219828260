<!-- nav -->
<ng-http-loader></ng-http-loader> 

<nav class="navbar" *ngIf="currentUser">    
        <div class="col-sm align-self-start justify-content-start">
            <div class="row justify-content-start">
                <img src="../assets/image_proyten.png" height="50px"/>
            </div>
        </div>
        <div class="col-sm" style="display: block; margin-left: auto; margin-right: auto;">
            <div class="row justify-content-center">
                <img src="../assets/image_brighten.png" height="50px"/>
            </div>
        </div>
        <div class="col-sm">
            <div class="row justify-content-end">
                <a class="nav-item nav-link d-inline" routerLink="/">Dashboard</a>
                <a class="nav-item nav-link d-inline" routerLink="/management" (click)="management()" *ngIf="currentUser.role == 'ADMIN'">Management</a>
                <a class="nav-item nav-link d-inline" (click)="logout()">Logout</a>
            </div>            
        </div>
        <!-- <a class="nav-item nav-link" routerLink="/"></a> -->
</nav>

<router-outlet></router-outlet>