<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <div class="card-header">
            <img src="../../assets/Schermata_login.jpg" style="width: 100%;"/>
            <div class="row">
                <div class="col-md-auto">
                    <img src="../../assets/Schermata_login_2.jpg" style="width: 100px;"/>
                </div>
                <div class="col-sm">
                    <h4 style="color: white;">LOGIN</h4>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username"><h6>Username</h6></label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password"><h6>Password</h6></label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>