import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '@app/_services';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.less']
})
export class ConfigurationComponent implements OnInit,OnDestroy {

  @Input() data;

  constructor(private _snackBar: MatSnackBar,private userService:UserService, private changeDectRef: ChangeDetectorRef) {    
  }

  ngOnDestroy(){
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  updateConfiguration(configuration){
    this.data['configuration'] = configuration;
    this.changeDectRef.markForCheck();
  }

  salvaConfigurazione(){
    let that = this;
    this.userService.caonfiguration(this.data['configuration'],this.data['id']).subscribe(res=>{
      that._snackBar.open("Configurazione salvata","Ok");
    });
  }
  
}
