import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdduserComponent } from '@app/adduser/adduser.component';
import { UserService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {
  selectedUser : any;
  logs:  any[] = [];
  dataSource : MatTableDataSource<any> ;//new MatTableDataSource<any>();
  loading = false;
  title = 'pagination';
  @Input() device;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns : string[] = [ 'name', 'surname', 'email','userName'];

  constructor(private http: HttpClient, private userService: UserService,private modalService : NgbModal ) {
    
  }
  
  ngOnInit() {
    this.getData('0', '5');
  }

  getData(offset, limit){
    let params = new HttpParams();
    params = params.set('offset', offset);
    params = params.set('limit', limit);
    this.userService.getUsers(params).subscribe( (response: any) =>{
      this.logs = response["users"];
      this.logs.length = response['total'];
      this.dataSource = new MatTableDataSource<any>(this.logs);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
    })
  }
  selectUser(user ){
    this.selectedUser  = user;
  }
  
  
  addUser(){
    const modalRef = this.modalService.open(AdduserComponent);
    modalRef.componentInstance.name = 'World';
  }


  getNextData(currentSize, offset, limit){
    let params = new HttpParams();
    params = params.set('offset', offset);
    params = params.set('limit', limit);

    this.userService.getUsers(params).subscribe((response: any[]) =>{
      this.logs.length = currentSize;      
      this.logs.push(...response["users"]);  
      this.logs.length =  response['total'];
      this.dataSource = new MatTableDataSource<any>(this.logs);
      console.log(this.dataSource);
      this.dataSource._updateChangeSubscription();
      this.dataSource.paginator = this.paginator;  
    })
  }

  pageChanged(event){
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    console.log("Page Index: " + pageIndex);
    console.log("Page size: " + pageSize);
    console.log("Previous size: " + previousSize);
    console.log("Previous index: " + previousIndex);

    this.getNextData(previousSize, (pageIndex*pageSize).toString(), pageSize.toString());
  }

}
