<div class="col-md-6 offset-md-3 mt-5">

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group" [hidden]="true">
            <label for="id_user">id_user </label>
            <input  type="text" formControlName="id_user" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.id_user.errors }" />
            <div *ngIf="submitted && f.id_user.errors" class="invalid-feedback">
                <div *ngIf="f.id_user.errors.required"></div>
            </div>
        </div>
        <div class="form-group">
            <label for="device_serial_number">Serial Number </label>
            <input type="text" formControlName="device_serial_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.device_serial_number.errors }" />
            <div *ngIf="submitted && f.device_serial_number.errors" class="invalid-feedback">
                <div *ngIf="f.device_serial_number.errors.required">Serial Number is required</div>
            </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    </form>
</div>