<div class="col-md-6 offset-md-3 mt-5">

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">Name </label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="surname">Surname </label>
            <input type="text" formControlName="surname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.surname.errors }" />
            <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
                <div *ngIf="f.surname.errors.required">Surname is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="email">Email </label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="userName">Username </label>
            <input type="text" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
            <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                <div *ngIf="f.userName.errors.required">UserName is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">Password </label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    </form>
</div>