export const environment = {
    production: true,
    apiUrl: 'https://devices.brighten.cloud/',
    loginPath : 'authenticate',
    userDevicesUrl: 'v1/user/devices/{userId}',
    usersPath : 'backend/public/users/v1',
    devicesPath : 'v1/admin/devices',
    devicePath : 'backend/public/poweranalyzer/v1/datauserdevice/id_user/id_device',
    configurationPath : "v1/admin/config/device/{deviceId}/{userId}",
    logsUrl : "v1/admin/devicelog",
    usersUrl : "v1/admin/userlist",
    devicesUrl : "v1/admin/devicelist",
    addDevice : "v1/admin/device",
    associateDevice : "v1/admin/association",
    addUser : "v1/admin/user",

};
