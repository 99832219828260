<div class="row">
  <div class="col-md-6">
    <div>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Users
            <div   class="row" style="margin-top: 15px;">
              <div class="col-md-1">
                  <button  (click)="addUser()" class="btn btn-primary">Add User
                  </button>
              </div>
          </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-paginator
          [pageSizeOptions]="[5,10,15,20]"
          showFirstLastButtons (page)="pageChanged($event)">
        </mat-paginator>
         <div *ngIf="loading;else table">
          <mat-spinner class="center"></mat-spinner>
        </div>    
        <ng-template #table>
          <table mat-table [dataSource]="dataSource" style="width: 100%;">          
            <ng-container matColumnDef="id" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Id </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{element.id}} </td>        
            </ng-container>
            <ng-container matColumnDef="name" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Name </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{ element.name}} </td>    
            </ng-container>
            <ng-container matColumnDef="surname" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Surname </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{ element.surname}} </td>    
            </ng-container> 
            <ng-container matColumnDef="email" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Email </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{ element.email}} </td>    
            </ng-container>    
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef> UserName </th>
              <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr (click)="selectUser(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-template>
        </mat-card-content>
      </mat-card>  
    </div>
  </div>
  <div class="col-md-6">
    <app-devices [relatedDevice]="true" [user]="selectedUser"></app-devices>
  </div>
</div>