import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, UserService } from '@app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-associatedevice',
  templateUrl: './associatedevice.component.html',
  styleUrls: ['./associatedevice.component.less']
})
export class AssociatedeviceComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  public idUser : any;
  constructor(
      private formBuilder: FormBuilder,private userService : UserService
  ) { 
      
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
        id_user : [this.idUser, Validators.required],
        device_serial_number: ['', Validators.required]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.userService.associateDevice(this.loginForm.getRawValue()).subscribe(res=>{
        alert("Saved.")
        location.reload();
      }, err=>{
          alert("Error during operation.")
      });
  }

}
