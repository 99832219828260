import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder } from "@angular/forms";

import { User } from '@app/_models';
import { AuthenticationService, UserService } from '@app/_services';

@Component({ 
    templateUrl: 'home.component.html' ,   
    styleUrls: ['./home.component.css']})
export class HomeComponent {
    loading = false;
    users: User[];
    devices;

    devicesCollapsed : boolean;

    rows: number ;
    cols: number ;
    count : number;

    data : object;
    rows_tables : number;


    constructor(private service: UserService, private authenticationService : AuthenticationService) { 
        this.devicesCollapsed = false;
        this.cols = 3;
        this.count = 0;
        this.devices = [];
    }

    getCount()  {
        let toRet = this.count;
        this.count++;
        return toRet;
    }
    arrayOne(n: number): any[] {
        return Array(n);
      }
    ngOnInit() {
        this.loading = true;
        let role = this.authenticationService.currentUserValue['role'];
        if(role == "ADMIN"){
            this.service.getAllDevices().pipe(first()).subscribe(devices => {
                this.loading = false;  
                this.devices = devices;
                this.rows = Math.ceil(this.devices.length/this.cols);
            });
        } else {
            this.service.getUserDevices().pipe(first()).subscribe(devices => {
                this.loading = false;  
                this.devices = devices;
                this.rows = Math.ceil(this.devices.length/this.cols);
            });        
       }
    }
    deviceSelectedId : number;
    onSelectChange(misura,event){
        this.data[misura]['selected']=event;
    }
    deviceDetail(device){
        this.deviceSelectedId = device.id;
        this.devicesCollapsed = true;
        this.data=device;
    }
}