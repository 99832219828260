<mat-card class="rectangle-8 tp-card">
    <mat-card-header-text>
        <mat-card-title class="title-configuration ">Devices</mat-card-title>
    </mat-card-header-text>
    <mat-card-content>
        <div *ngIf="!devicesCollapsed">
            <div class="row" *ngFor="let row of arrayOne(rows); let i = index;">
                <div class="col-md-4" *ngFor="let col of arrayOne(3); let j = index;">
                    <mat-card *ngIf="!!devices[i+j+(2*i)]" style="cursor: pointer; margin-top: 15px;"
                        (click)="deviceDetail(devices[i+j+(2*i)])" class="example-card tp-card">
                        <mat-card-header>
                            <div mat-card-avatar class="example-header-image"></div>
                            <mat-card-title class="title-configuration">{{devices[i+j+(2*i)].aliasName}}</mat-card-title>
                            <mat-card-subtitle>{{devices[i+j+(2*i)].serialNumber}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            {{devices[i+j+(2*i)].description + " "}}
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions *ngIf="devicesCollapsed">
        <button mat-button (click)="devicesCollapsed=false">Visualizza tutti</button>
    </mat-card-actions>
</mat-card>

<mat-card class="rectangle-8 tp-card " style="margin-top: 10px;" *ngIf=" !!data">
    <mat-card-header-text>
        <mat-card-title class="title-configuration "></mat-card-title>
    </mat-card-header-text>
    <mat-card-content>
        <div *ngFor="let dev of devices; let index = d" >
            <app-configuration *ngIf="deviceSelectedId == dev.id" [data]="dev" ></app-configuration>
        </div>
    </mat-card-content>
</mat-card>