import { Component, OnInit , NgZone, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
//import { MqttCommunicationService } from '../mqtt-communication.service';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-mqtt',
  templateUrl: './mqtt.component.html',
  styleUrls: ['./mqtt.component.less']
})
export class MqttComponent implements OnInit,OnDestroy {

  topicMonitoring: string;
  private subscription: Subscription;
  private message: IMqttMessage;

  statusLight: number[] = [];
  linesStatus: number[] = [];
  faultArray: number[] = [];

  @Input() topic;
  @Output() confEmitter = new EventEmitter();

  constructor(
              private mqttService: MqttService,
              private ngZone: NgZone,
              private router: Router) {
             //   console.log(this.router.getCurrentNavigation().extras);
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    var that = this;
    that.topicMonitoring = this.topic;
    this.subscription = this.mqttService.observe(that.topicMonitoring).subscribe((message: IMqttMessage) => {
      var configuration = JSON.parse(message.payload.toString());
      console.log("MQTT DATA" ,this.topic ,configuration);
      that.ngZone.run(()=> {
        this.confEmitter.emit(configuration);
      });
      /*
      console.log('msg: ', message);
      console.log('Message: ' + message.payload.toString() + ' for topic: ' + message.topic);
      */
    });
    
    /*
    this.mqttCommunicationService.statusLightEvent.subscribe((value) => {
      that.statusLight = value;
      console.log(value);
    });
    this.mqttCommunicationService.faultEvent.subscribe((value) => {
      that.faultArray = value;
      console.log(value);
    });
    this.mqttCommunicationService.statusLightEvent.subscribe((value) => {
      that.linesStatus = value;
      console.log(value);
    });
    */

  }

  

}
