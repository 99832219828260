<div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Logs
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20, 30]"
        showFirstLastButtons (page)="pageChanged($event)">
      </mat-paginator>
       <div *ngIf="loading;else table">
        <mat-spinner class="center"></mat-spinner>
      </div>    
      <ng-template #table>
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
          <ng-container matColumnDef="id" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Id </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{element.id}} </td>        
            </ng-container>        
          <ng-container matColumnDef="timestampEvent" >
            <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Timestamp </th>
            <td mat-cell *matCellDef="let element" style="width: 30%;"> {{ element.timestampEvent}} </td>    
          </ng-container>    
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Evento </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
          </ng-container>      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-template>
      </mat-card-content>
    </mat-card>  
  </div>