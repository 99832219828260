import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
   
    user : {}
    constructor(private http: HttpClient, private authenticationService : AuthenticationService) { 
        this.user  = authenticationService.currentUserValue;
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}${environment.usersPath}`);
    }
    getAllDevices() {
       
        return this.http.get(`${environment.apiUrl}${environment.devicesPath}`);
    }
    getUserDevices(){
        let userId = this.authenticationService.currentUserValue["id"];
        return this.http.get(environment.apiUrl + environment.userDevicesUrl.replace("{userId}",userId+""));
    }
    getLogs(params) {
        return this.http.get(environment.apiUrl + environment.logsUrl + '?' + params.toString());
    }
    getUsers(params) {
        return this.http.get(environment.apiUrl+environment.usersUrl+ '?' + params.toString());
    }
    getDevices(params) {
        return this.http.get(environment.apiUrl+environment.devicesUrl+ '?' + params.toString());
    }
    caonfiguration(configuration: any, deviceId: any) {
        let userId = this.authenticationService.currentUserValue["id"];
        let url = `${environment.apiUrl}${environment.configurationPath}`.replace("{deviceId}",deviceId).replace("{userId}",userId+"");
        return this.http.post<any>(url, configuration);
        
    }

    addDevice ( data ){
        let url = `${environment.apiUrl}${environment.addDevice}`;
        return this.http.post<any>(url, data);
    }
    addUser ( data ){
        let url = `${environment.apiUrl}${environment.addUser}`;
        return this.http.post<any>(url, data);
    }
    associateDevice (data ){
        let url = `${environment.apiUrl}${environment.associateDevice}`;
        return this.http.post<any>(url, data);
    }
}