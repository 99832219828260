import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdddeviceComponent } from '@app/adddevice/adddevice.component';
import { AssociatedeviceComponent } from '@app/associatedevice/associatedevice.component';
import { UserService } from '@app/_services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.less']
})
export class DevicesComponent implements OnInit,OnChanges {

  logs:  any[] = [];
  dataSource : MatTableDataSource<any> ;//new MatTableDataSource<any>();
  loading = false;
  title = 'pagination';
  @Input() device;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns : string[] = [ 'aliasName','serialNumber'];
  @Input()  relatedDevice : false;
  @Input() user : any[];
  pageSizeOptions = [5, 10, 15, 20, 30];
  constructor(private http: HttpClient, private userService: UserService,private modalService: NgbModal) {
    
  }
  ngOnChanges(changes: SimpleChanges): void {
       
    if(!this.user){
      return;
    }
    this.logs = this.user['devices']; 
    this.logs.length = this.user['devices'].length;
    this.dataSource = new MatTableDataSource<any>(this.logs);
    this.dataSource.paginator = this.paginator;
    this.dataSource._updateChangeSubscription();
  }
  
  ngOnInit() {
    if(this.relatedDevice){
      this.pageSizeOptions = [5];
    }
    this.getData('0', '5');
  }
  addDevice(){
    const modalRef = this.modalService.open(AdddeviceComponent);
    modalRef.componentInstance.name = 'World';
  }
  associateDevice(){
    if(!this.user){
      alert("Choose user");
      return;
    }
    const modalRef = this.modalService.open(AssociatedeviceComponent);
    modalRef.componentInstance.idUser = this.user['id'];
  }
  getData(offset, limit){
    
    if(this.relatedDevice ){
      this.logs = this.user['devices'].slice(offset,limit+1);
      this.logs.length = this.user['devices'].length;
      this.dataSource = new MatTableDataSource<any>(this.logs);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
      return;
    }
    let params = new HttpParams();
    params = params.set('offset', offset);
    params = params.set('limit', limit);
    this.userService.getDevices(params).subscribe( (response: any) =>{
      this.logs = response["devices"];
      this.logs.length = response['total'];
      this.dataSource = new MatTableDataSource<any>(this.logs);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
    })
  }


  getNextData(currentSize, offset, limit){
    if(this.relatedDevice){
      this.logs = this.user['devices'].slice(offset,limit+1);
      this.logs.length = this.user['devices'].length;
      this.dataSource = new MatTableDataSource<any>(this.logs);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
      return;
    }

    let params = new HttpParams();
    params = params.set('offset', offset);
    params = params.set('limit', limit);

    this.userService.getDevices(params).subscribe((response: any[]) =>{
      this.logs.length = currentSize;      
      this.logs.push(...response["devices"]);  
      this.logs.length =  response['total'];
      this.dataSource = new MatTableDataSource<any>(this.logs);
      console.log(this.dataSource);
      this.dataSource._updateChangeSubscription();
      this.dataSource.paginator = this.paginator;  
    })
  }

  pageChanged(event){
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    console.log("Page Index: " + pageIndex);
    console.log("Page size: " + pageSize);
    console.log("Previous size: " + previousSize);
    console.log("Previous index: " + previousIndex);

    this.getNextData(previousSize, (pageIndex*pageSize).toString(), pageSize.toString());
  }
}
