import { NgModule , NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgHttpLoaderModule } from 'ng-http-loader';

import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { appRoutingModule } from './app.routing';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import { FormsModule }  from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { IMqttMessage, MqttModule, IMqttServiceOptions } from "ngx-mqtt";;
import { MqttComponent } from './mqttComponent/mqtt/mqtt.component'
;
import { ConfigurationComponent } from './configuration/configuration.component'
;
import { LogsComponent } from './logs/logs.component'

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';;
import { ManagementComponent } from './management/management.component'
;
import { UsersComponent } from './users/users.component'
;
import { DevicesComponent } from './devices/devices.component'
;
import { AdddeviceComponent } from './adddevice/adddevice.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';;
import { AssociatedeviceComponent } from './associatedevice/associatedevice.component'
;
import { AdduserComponent } from './adduser/adduser.component'
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'devices.brighten.cloud',
    username: 'brighten',
    password: 'd3Vbr1ght3n2021',
    port: 61615,
    protocol: 'wss',
    path: '/mqtt'
  }
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSnackBarModule,
        NgHttpLoaderModule.forRoot(),
        appRoutingModule,
        MatCardModule,
        MatGridListModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        MatFormFieldModule,
        RouterModule,
        MatSliderModule,
        MatSlideToggleModule,
        BrowserAnimationsModule,
        MatTableModule,
        FormsModule ,
        HighchartsChartModule ,
        
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        NgbModule   ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent ,
        MqttComponent ,
        ConfigurationComponent ,
        LogsComponent ,
        ManagementComponent,
        UsersComponent,
        DevicesComponent ,
        AdddeviceComponent ,
        AssociatedeviceComponent ,
        AdduserComponent ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide : LocationStrategy , useClass: HashLocationStrategy},
        MatSnackBar
        // provider used to create fake backend
    ],
    bootstrap: [AppComponent],
    entryComponents: [AdddeviceComponent,AssociatedeviceComponent,AdduserComponent],
    schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }