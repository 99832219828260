import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.less']
})
export class AdduserComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
      private formBuilder: FormBuilder,private userService : UserService
  ) { 
      
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
        name: ['', Validators.required],
        surname: ['', Validators.required],
        userName: ['', Validators.required],
        password : ['', Validators.required],
        email : ['', Validators.required]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.userService.addUser(this.loginForm.getRawValue()).subscribe(res=>{
        alert("Saved.")
        location.reload();
      }, err=>{
          alert("Error during operation.")
      });
  }

}
