<mat-card>
    <mat-card-header>
      <mat-card-title>
      

<p>Configurazione device: <b>{{data.aliasName}}</b><br>Seriale: <b>{{data.serialNumber}}</b><br>Ultimo aggiornamento:
    <b>{{data.configuration.savedTimestamp}} </b></p>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label> Modalita' Operativa Lampada </mat-label>
                    <mat-select [(ngModel)]="data.configuration.operatingMode">
                        <mat-option [value]="0">Disabilitato</mat-option>
                        <mat-option [value]="1">Timer giornaliero</mat-option>
                        <mat-option [value]="2">Timer</mat-option>
                    </mat-select>
                </mat-form-field><br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- 0 - vuoto - disp. disabilitato -->
                <div *ngIf="data.configuration.operatingMode==2" class="row">
                    <div class="col-md-4">
                        <mat-form-field class="example-form-field"  appearance="fill">
                            <mat-label>Tempo lampada</mat-label>
                            <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timeLamp">
                        </mat-form-field>                        
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-form-field"  appearance="fill">
                            <mat-label>Timer</mat-label>
                            <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timerMinutes">
                        </mat-form-field>                        
                    </div>
                </div>
                <hr>
                <!-- 1 - - disp. disabilitato 
                    : 30
                    timeLamp: 1
                    
                    -->
                <div *ngIf="data.configuration.operatingMode==1" class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="example-form-field"  appearance="fill">
                                    <mat-label>Tempo lampada</mat-label>
                                    <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timeLamp">
                                </mat-form-field>                        
                            </div>
                        </div>
                        <hr>
                       <div class="row">
                           <div class="col-md-4">
                            <mat-form-field appearance="fill">
                                <mat-label> Timer giornaliero 1</mat-label>
                                <mat-select [(ngModel)]="data.configuration.timerDaily1Enabled">
                                    <mat-option [value]="false">Disabilitato</mat-option>
                                    <mat-option [value]="true">Abilitato</mat-option>
                                </mat-select>
                            </mat-form-field>
                           </div>
                           <div class="col-md-4">
                            <mat-form-field class="example-form-field"  appearance="fill">
                                <mat-label>Ore</mat-label>
                                <input [disabled]="!data.configuration.timerDaily1Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily1Hour">
                            </mat-form-field> 
                          </div>
                            <div class="col-md-4">
                                <mat-form-field class="example-form-field"  appearance="fill">
                                    <mat-label>Minuti</mat-label>
                                    <input [disabled]="!data.configuration.timerDaily1Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily1Minute">
                                </mat-form-field> 
                            </div>
                       </div>
                        <hr>
                       <div class="row">
                        <div class="col-md-4">
                         <mat-form-field appearance="fill">
                             <mat-label> Timer giornaliero 2</mat-label>
                             <mat-select [(ngModel)]="data.configuration.timerDaily2Enabled">
                                 <mat-option [value]="false">Disabilitato</mat-option>
                                 <mat-option [value]="true">Abilitato</mat-option>
                             </mat-select>
                         </mat-form-field>
                        </div>
                        <div class="col-md-4">
                         <mat-form-field class="example-form-field"  appearance="fill">
                             <mat-label>Ore</mat-label>
                             <input [disabled]="!data.configuration.timerDaily2Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily2Hour">
                         </mat-form-field> 
                       </div>
                         <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Minuti</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily2Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily2Minute">
                             </mat-form-field> 
                         </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                         <mat-form-field appearance="fill">
                             <mat-label> Timer giornaliero 3</mat-label>
                             <mat-select [(ngModel)]="data.configuration.timerDaily3Enabled">
                                 <mat-option [value]="false">Disabilitato</mat-option>
                                 <mat-option [value]="true">Abilitato</mat-option>
                             </mat-select>
                         </mat-form-field>
                        </div>
                        <div class="col-md-4">
                         <mat-form-field class="example-form-field"  appearance="fill">
                             <mat-label>Ore</mat-label>
                             <input [disabled]="!data.configuration.timerDaily3Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily3Hour">
                         </mat-form-field> 
                       </div>
                         <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Minuti</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily3Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily3Minute">
                             </mat-form-field> 
                         </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                         <mat-form-field appearance="fill">
                             <mat-label> Timer giornaliero 4</mat-label>
                             <mat-select [(ngModel)]="data.configuration.timerDaily4Enabled">
                                 <mat-option [value]="false">Disabilitato</mat-option>
                                 <mat-option [value]="true">Abilitato</mat-option>
                             </mat-select>
                         </mat-form-field>
                        </div>
                        <div class="col-md-4">
                         <mat-form-field class="example-form-field"  appearance="fill">
                             <mat-label>Ore</mat-label>
                             <input [disabled]="!data.configuration.timerDaily4Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily4Hour">
                         </mat-form-field> 
                       </div>
                         <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Minuti</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily4Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily4Minute">
                             </mat-form-field> 
                         </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                         <mat-form-field appearance="fill">
                             <mat-label> Timer giornaliero 5</mat-label>
                             <mat-select [(ngModel)]="data.configuration.timerDaily5Enabled">
                                 <mat-option [value]="false">Disabilitato</mat-option>
                                 <mat-option [value]="true">Abilitato</mat-option>
                             </mat-select>
                         </mat-form-field>
                        </div>
                        <div class="col-md-4">
                         <mat-form-field class="example-form-field"  appearance="fill">
                             <mat-label>Ore</mat-label>
                             <input [disabled]="!data.configuration.timerDaily5Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily5Hour">
                         </mat-form-field> 
                       </div>
                         <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Minuti</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily5Enabled" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily5Minute">
                             </mat-form-field> 
                         </div>
                    </div>
                    </div>
                </div>
            </div>
    </div>
    </div>
    <hr>
    
    <div class="col-md-2">
        <mat-form-field appearance="fill">
            <mat-label> Ingresso 1 </mat-label>
            <mat-select [(ngModel)]="data.configuration.input1">
                <mat-option [value]="true">N.C.</mat-option>
                <mat-option [value]="false">N.O.</mat-option>
            </mat-select>
        </mat-form-field><br>
        <mat-form-field appearance="fill">
            <mat-label> Ingresso 2 </mat-label>
            <mat-select [(ngModel)]="data.configuration.input2">
                <mat-option [value]="true">N.C.</mat-option>
                <mat-option [value]="false">N.O.</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label> Ingresso 3 </mat-label>
            <mat-select [(ngModel)]="data.configuration.input3">
                <mat-option [value]="true">N.C.</mat-option>
                <mat-option [value]="false">N.O.</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label> Allarme carico </mat-label>
            <mat-select [(ngModel)]="data.configuration.ifaultEnable">
                <mat-option [value]="false">Disabilitato</mat-option>
                <mat-option [value]="true">Abilitato</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field class="example-form-field">
            <mat-label>Input 1</mat-label>
            <input matInput type="text" [(ngModel)]="data.configuration.input1">
             <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
              <mat-icon>close</mat-icon>
            </button> 
          </mat-form-field> -->
    </div>
    <div class="col-md-4">
        <app-logs [device]="data"></app-logs>
    </div>
</div>
    <hr>
    <!-- Configurazione Purificatore aria -->
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="fill">
                        <mat-label> Modalita' Operativa Purificatore Aria </mat-label>
                        <mat-select [(ngModel)]="data.configuration.operatingModeFan">
                            <mat-option [value]="0">Disabilitato</mat-option>
                            <mat-option [value]="1">Timer giornaliero</mat-option>
                            <mat-option [value]="2">Timer</mat-option>
                        </mat-select>
                    </mat-form-field><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- 0 - vuoto - disp. disabilitato -->
                    <div *ngIf="data.configuration.operatingModeFan==2" class="row">
                        <div class="col-md-4">
                            <mat-form-field class="example-form-field"  appearance="fill">
                                <mat-label>Tempo Purificatore Aria</mat-label>
                                <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timeFan">
                            </mat-form-field>                        
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-form-field"  appearance="fill">
                                <mat-label>Timer</mat-label>
                                <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timerMinutesFan">
                            </mat-form-field>                        
                        </div>
                    </div>
                    <hr>
                    <!-- 1 - - disp. disabilitato 
                        : 30
                        timeLamp: 1
                        
                        -->
                    <div *ngIf="data.configuration.operatingModeFan==1" class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="example-form-field"  appearance="fill">
                                        <mat-label>Tempo Purificatore Aria</mat-label>
                                        <input oninput="this.value = Math.abs(this.value)" matInput type="number" min="1"  [(ngModel)]="data.configuration.timeFan">
                                    </mat-form-field>                        
                                </div>
                            </div>
                            <hr>
                           <div class="row">
                               <div class="col-md-4">
                                <mat-form-field appearance="fill">
                                    <mat-label> Timer giornaliero 1</mat-label>
                                    <mat-select [(ngModel)]="data.configuration.timerDaily1EnabledFan">
                                        <mat-option [value]="false">Disabilitato</mat-option>
                                        <mat-option [value]="true">Abilitato</mat-option>
                                    </mat-select>
                                </mat-form-field>
                               </div>
                               <div class="col-md-4">
                                <mat-form-field class="example-form-field"  appearance="fill">
                                    <mat-label>Ore</mat-label>
                                    <input [disabled]="!data.configuration.timerDaily1EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily1HourFan">
                                </mat-form-field> 
                              </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-form-field"  appearance="fill">
                                        <mat-label>Minuti</mat-label>
                                        <input [disabled]="!data.configuration.timerDaily1EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily1MinuteFan">
                                    </mat-form-field> 
                                </div>
                           </div>
                            <hr>
                           <div class="row">
                            <div class="col-md-4">
                             <mat-form-field appearance="fill">
                                 <mat-label> Timer giornaliero 2</mat-label>
                                 <mat-select [(ngModel)]="data.configuration.timerDaily2EnabledFan">
                                     <mat-option [value]="false">Disabilitato</mat-option>
                                     <mat-option [value]="true">Abilitato</mat-option>
                                 </mat-select>
                             </mat-form-field>
                            </div>
                            <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Ore</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily2EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily2HourFan">
                             </mat-form-field> 
                           </div>
                             <div class="col-md-4">
                                 <mat-form-field class="example-form-field"  appearance="fill">
                                     <mat-label>Minuti</mat-label>
                                     <input [disabled]="!data.configuration.timerDaily2EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily2MinuteFan">
                                 </mat-form-field> 
                             </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                             <mat-form-field appearance="fill">
                                 <mat-label> Timer giornaliero 3</mat-label>
                                 <mat-select [(ngModel)]="data.configuration.timerDaily3EnabledFan">
                                     <mat-option [value]="false">Disabilitato</mat-option>
                                     <mat-option [value]="true">Abilitato</mat-option>
                                 </mat-select>
                             </mat-form-field>
                            </div>
                            <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Ore</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily3EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily3HourFan">
                             </mat-form-field> 
                           </div>
                             <div class="col-md-4">
                                 <mat-form-field class="example-form-field"  appearance="fill">
                                     <mat-label>Minuti</mat-label>
                                     <input [disabled]="!data.configuration.timerDaily3EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily3MinuteFan">
                                 </mat-form-field> 
                             </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                             <mat-form-field appearance="fill">
                                 <mat-label> Timer giornaliero 4</mat-label>
                                 <mat-select [(ngModel)]="data.configuration.timerDaily4EnabledFan">
                                     <mat-option [value]="false">Disabilitato</mat-option>
                                     <mat-option [value]="true">Abilitato</mat-option>
                                 </mat-select>
                             </mat-form-field>
                            </div>
                            <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Ore</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily4EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily4HourFan">
                             </mat-form-field> 
                           </div>
                             <div class="col-md-4">
                                 <mat-form-field class="example-form-field"  appearance="fill">
                                     <mat-label>Minuti</mat-label>
                                     <input [disabled]="!data.configuration.timerDaily4EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily4MinuteFan">
                                 </mat-form-field> 
                             </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                             <mat-form-field appearance="fill">
                                 <mat-label> Timer giornaliero 5</mat-label>
                                 <mat-select [(ngModel)]="data.configuration.timerDaily5EnabledFan">
                                     <mat-option [value]="false">Disabilitato</mat-option>
                                     <mat-option [value]="true">Abilitato</mat-option>
                                 </mat-select>
                             </mat-form-field>
                            </div>
                            <div class="col-md-4">
                             <mat-form-field class="example-form-field"  appearance="fill">
                                 <mat-label>Ore</mat-label>
                                 <input [disabled]="!data.configuration.timerDaily5EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="23"  [(ngModel)]="data.configuration.timerDaily5HourFan">
                             </mat-form-field> 
                           </div>
                             <div class="col-md-4">
                                 <mat-form-field class="example-form-field"  appearance="fill">
                                     <mat-label>Minuti</mat-label>
                                     <input [disabled]="!data.configuration.timerDaily5EnabledFan" oninput="this.value = Math.abs(this.value)" matInput type="number" min="0" max="59"  [(ngModel)]="data.configuration.timerDaily5MinuteFan">
                                 </mat-form-field> 
                             </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<hr>
<br>
<br>
<br>
<div class="row">
    <div class="col-md-4"> 
        <button mat-flat-button color="warn" (click)="salvaConfigurazione()">Salva</button>
    </div>
</div>
</mat-card-content>
<app-mqtt  [topic]="data.topicWeb" (confEmitter)="updateConfiguration($event)" ></app-mqtt>