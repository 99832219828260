<div class="col-md-6 offset-md-3 mt-5">

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="aliasName">Alias </label>
                    <input type="text" formControlName="aliasName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.aliasName.errors }" />
                    <div *ngIf="submitted && f.aliasName.errors" class="invalid-feedback">
                        <div *ngIf="f.aliasName.errors.required">AliasName is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="description">Description </label>
                    <input type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" />
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="serialNumber">Serial Number </label>
                    <input type="text" formControlName="serialNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.serialNumber.errors }" />
                    <div *ngIf="submitted && f.serialNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.serialNumber.errors.required">Serial Number is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>