
<div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div *ngIf="!relatedDevice" >
            Devices
          </div>
          <div *ngIf="relatedDevice">
            Associated Devices - {{user?.userName}}
          </div>
          <div *ngIf="!relatedDevice" class="row" style="margin-top: 15px;">
            <div class="col-md-1">
                <button  (click)="addDevice()" class="btn btn-primary">Add Device
                </button>
            </div>
        </div>
        <div *ngIf="relatedDevice" class="row" style="margin-top: 15px;">
            <div class="col-md-1">
                <button  (click)="associateDevice()" class="btn btn-primary">Associate Device
                </button>
            </div>
        </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons (page)="pageChanged($event)">
      </mat-paginator>
       <div *ngIf="loading;else table">
        <mat-spinner class="center"></mat-spinner>
      </div>    
      <ng-template #table>
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
          <ng-container matColumnDef="id" >
              <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Id </th>
              <td mat-cell *matCellDef="let element" style="width: 30%;"> {{element.id}} </td>        
            </ng-container>        
            <ng-container matColumnDef="serialNumber">
              <th mat-header-cell *matHeaderCellDef> Serial Number </th>
              <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
            </ng-container>     
          <ng-container matColumnDef="aliasName" >
            <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Description </th>
            <td mat-cell *matCellDef="let element" style="width: 30%;"> {{ element.aliasName}} </td>    
          </ng-container>    
          <ng-container matColumnDef="topicWeb">
            <th mat-header-cell *matHeaderCellDef> Topic </th>
            <td mat-cell *matCellDef="let element"> {{element.topicWeb}} </td>
          </ng-container>  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-template>
      </mat-card-content>
    </mat-card>  
  </div>