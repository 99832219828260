import { Component, ViewChild,OnInit, Input, AfterViewInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '@app/_services';
import { MatSort } from '@angular/material/sort';
import { EventLog } from '../_models/event';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.less']
})

export class LogsComponent implements OnInit {
  logs:  EventLog[] = [];
  dataSource : MatTableDataSource<EventLog> ;//new MatTableDataSource<EventLog>();
  loading = false;
  title = 'pagination';
  @Input() device;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns : string[] = ['timestampEvent', 'message'];

  constructor(private http: HttpClient, private userService: UserService) {
    
  }
  
  ngOnInit() {
    this.getData('0', '5');
  }

  getData(offset, limit){
    let params = new HttpParams();
    params = params.set("device",this.device['id']);
    params = params.set('offset', offset);
    params = params.set('limit', limit);
    
    this.userService.getLogs(params).subscribe( (response: any) =>{
      this.logs = response["logs"];
      this.logs.length = response['total'];
      this.dataSource = new MatTableDataSource<EventLog>(this.logs);
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
    })
  }

  getNextData(currentSize, offset, limit){
    let params = new HttpParams();
    params = params.set("device",this.device['id']);
    params = params.set('offset', offset);
    params = params.set('limit', limit);

    this.userService.getLogs(params).subscribe((response: EventLog[]) =>{
      this.logs.length = currentSize;      
      this.logs.push(...response["logs"]);  
      this.logs.length =  response['total'];
      this.dataSource = new MatTableDataSource<EventLog>(this.logs);
      console.log(this.dataSource);
      this.dataSource._updateChangeSubscription();
      this.dataSource.paginator = this.paginator;  
    })
  }

  pageChanged(event){
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;
    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    console.log("Page Index: " + pageIndex);
    console.log("Page size: " + pageSize);
    console.log("Previous size: " + previousSize);
    console.log("Previous index: " + previousIndex);

    this.getNextData(previousSize, (pageIndex*pageSize).toString(), pageSize.toString());
  }
}

